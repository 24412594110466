.app-container {
  padding: 0;
}

.navbar {
  height: 60px; /* Limita la altura del Header */
  padding: 0 10px; /* Ajusta el padding según sea necesario */
  background-color: #f8f9fa; /* Color de fondo del Header */
}

.sidebar-container {
  height: calc(100vh - 50px); /* Ajusta la altura de la barra lateral para restar la altura del Header */
  background-color: #e9ecef; /* Color de fondo de la barra lateral */
}

.content-container {
  padding: 30px; /* Espacio interno del contenido principal */
  height: calc(100vh - 50px); /* Ajusta la altura del contenido para restar la altura del Header */
  overflow-y: auto; /* Permite el desplazamiento si el contenido es mayor que la altura */
}
