/* HeroSection.css */

.hero-section {
  position: relative;
}

.hero-image {
  width: 100%; /* La imagen ocupará el ancho completo del contenedor */
  height: auto; /* La altura se ajustará automáticamente para mantener la proporción */
  max-height: 500px; /* Altura máxima de la imagen para evitar que sea demasiado alta */
  object-fit: cover; /* La imagen se ajustará para cubrir el contenedor sin deformarse */
}

.logo {
  position: absolute; /* Permite posicionar el logo encima de la imagen */
  top: 75%; /* Coloca el logo en el centro vertical */
  left: 50%; /* Coloca el logo en el centro horizontal */
  transform: translate(-50%, -50%); /* Centra el logo tanto vertical como horizontalmente */
  z-index: 1; /* Asegura que el logo esté por encima de la imagen de fondo */
  width: 40%; /* Define el ancho del logo en relación al contenedor padre */
  max-width: 350px; /* Establece un ancho máximo para el logo */
}

.hero-title {
  position: absolute;
  bottom: 10px; /* Ajusta este valor según sea necesario para posicionar el texto */
  left: 50%; /* Centra el texto horizontalmente */
  transform: translateX(-50%); /* Centra el texto horizontalmente */
  color: white; /* Establece el color del texto */
  text-align: center; /* Alinea el texto al centro */
  font-size: 2vw; /* Utiliza unidades de viewport para hacer el tamaño del texto proporcional al tamaño de la pantalla */
  font-weight: bold; /* Peso de fuente */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); /* Agrega sombra al texto */
}
