.custom-card {
    /* Estilo de la tarjeta */
    border: none; /* Elimina el borde de la tarjeta */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Agrega sombra a la tarjeta */
  }
  
  .card-body {
    padding: 20px; /* Añade un espacio de relleno al contenido de la tarjeta */
  }
  
  .card-title {
    color: #122DF0; /* Establece el color del título */
  }
  
  .card-text {
    color: #122DF0; /* Establece el color del texto */
  }
  
  @media (max-width: 768px) {
    /* Cambia el diseño para pantallas más pequeñas */
    .custom-card {
      display: flex;
      flex-direction: column; /* Cambia la disposición a columna en pantallas pequeñas */
    }
  
    .card-body {
      padding: 20px; /* Ajusta el espacio de relleno en pantallas pequeñas */
    }
  
    .card {
      text-align: center; /* Centra el contenido en pantallas pequeñas */
    }
  }
  