.admin-page {
    padding-top: 20px; /* Añade un espacio superior para separar el encabezado */
  }
  
  .page-title {
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  @media (max-width: 768px) {
    /* Estilos para pantallas pequeñas */
    .admin-page {
      padding-top: 10px; /* Reduce el espacio superior en pantallas pequeñas */
    }
  
    .page-title {
      font-size: 24px; /* Reduce el tamaño del título en pantallas pequeñas */
    }
  }
  