/* styles.css */

/* Estilos generales */
body {
  font-family: Arial, sans-serif;
  background-color: #ffffff; /* Fondo blanco */
  color: #000000; /* Texto negro */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Estilos para el header */
.header {
  background-color: #122DF0; /* Azul */
  color: #ffffff; /* Texto blanco */
  padding: 20px 0;
}

.header h1 {
  margin: 0;
}

/* Estilos para el carrusel */
.carousel {
  /* Estilos para el carrusel */
}

/* Estilos para el texto de bienvenida */
.landing-text {
  text-align: center;
  padding: 50px 0;
}

.landing-text h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.landing-text p {
  font-size: 18px;
}

/* Estilos para las imágenes */
.landing-images {
  /* Estilos para las imágenes */
}

/* Estilos para el footer */
.footer {
  background-color: #122DF0; /* Azul */
  color: #ffffff; /* Texto blanco */
  padding: 50px 0;
}

.footer p {
  margin-bottom: 20px;
}

.footer-content {
  text-align: center;
}

/* Estilos adicionales para hacer responsive */
@media (max-width: 768px) {
  .landing-text h2 {
    font-size: 24px;
  }
  
  .landing-text p {
    font-size: 16px;
  }
}
