.responsive-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .box {
    width: 100%;
    height: 200px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow-y: auto;
  }
  
  .prediction-text {
    white-space: pre-wrap;
  }
  
  .prediction-placeholder {
    color: #888;
  }
  