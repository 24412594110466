.feature-card-container {
    display: flex; /* Utiliza flexbox */
    justify-content: center; /* Centra horizontalmente los elementos */
    margin-bottom: 20px; /* Margen inferior para separar los FeatureCard */
  }
  
  /* Estilos para centrar horizontalmente la tarjeta */
  .card {
    width: 500px; /* Ancho fijo para todos los FeatureCard */
  }
  