.page {
    border: 2px solid #ccc;
    padding: 20px;
    max-width: 100%; /* Ajusta el ancho máximo del contenedor */
    margin: 0 auto; /* Centra el contenedor en la pantalla */
    position: relative;
    overflow: hidden;
}
.particles-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; /* Asegura que esté detrás del contenido */
}