/* HorasMedicas.css */
.horas-medicas-container {
    padding: 20px;
    margin: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .activities-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .activities-table th, .activities-table td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: left;
  }
  
  .activities-table th {
    background-color: #f2f2f2;
  }
  