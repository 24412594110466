/* Archivo: sidebar.css */

.sidebar {
  background-color: #343a40; /* Color de fondo de la barra lateral */
  color: #ffffff; /* Color del texto */
  height: 100%;
  padding: 20px 0; /* Espacio interno */
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  padding: 10px 20px;
  cursor: pointer;
}

.sidebar li:hover {
  background-color: #495057; /* Color de fondo al pasar el ratón */
}
